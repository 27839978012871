.container {
  .container {
    padding: 0;
    max-width: 100%;
  }
}

#page {
  &.category-detail {
    .header {
      padding: 15px 15px 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .is-headline {
        margin: 65px 0 0;
      }

      &::before,
      &::after {
        content: none;
      }
    }

    .category {
      &-logo {
        position: absolute;
        top: 70px;
        right: 15px;
        width: 100%;
        max-width: 90px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .breadcrumb {
      padding-top: 65px;
    }
  }

  &.author-detail,
  &.author-listing {
    .breadcrumb {
      padding-top: 65px;
    }

    .authors-single {
      @media(max-width: 767px) {
        margin-top: 35px;
      }
    }
  }

  &.author-detail {
    .article-title {
      margin-bottom: 15px;
    }
  }

  &.author-listing {
    @media(max-width: 767px) {
      .author-media {
        max-width: 60px;
        margin-right: 10px;
      }
    }
  }

  &.article-detail {
    .article {
      padding-top: 15px;

      @media(min-width: 768px) {
        padding-top: 0;
      }
    }

    #sidebar {
      .is-headline {
        margin: 15px 0 70px;
      }

      .category-teaser-overline {
        display: none !important; // hide overline: DEV-12052
        margin: 25px 0 15px;
      }

      .category-teaser-headline {
        margin-top: 15px;
      }
    }

    .category {
      &-logo {
        position: absolute;
        top: -10px;
        right: 0;
        width: 100%;
        max-width: 90px;

        img {
          width: 100%;
          height: auto;
        }
      }

      &-slider {
        &-media {
          overflow: hidden;
          border-radius: $border-radius;
          position: relative;
          margin-bottom: 10px;

          & > img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .article {
      position: relative;

      &-category {
        display: none !important; // hide overline: DEV-12052

        font-size: 12px;
        display: inline-block;
        color: $orange-color;
        font-family: $font-family;
        font-weight: bold;
        @media (min-width: 768px) {
          background: $orange-color;
          color: $white-color;
          padding: 5px 10px;
          position: absolute;
          top: 15px;
          right: 0;
          border-radius: 25px;
        }
      }

      &-image {
        .article-caption {
          font-family: $font-family;
          font-size: 0.875rem;
          text-align: left;
        }

        @media (max-width: 767px) {
          .article-caption {
            font-size: 12px;
          }
        }

        .article-source {
          font-family: $font-family;
          color: $gray-color;
          font-size: 0.75rem;
          margin-top: 5px;
          text-align: left;
        }

        @media (max-width: 767px) {
          .article-source {
            font-size: 10px;
          }
        }
      }

      &-media {
        overflow: hidden;
        border-radius: $border-radius;
        position: relative;
        margin-bottom: 10px;

        & > img {
          width: 100%;
          height: auto;
        }

        .acontainer {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &-gallery {
        margin: 0 0 25px;
      }

      &-meta {
        .socials {
          margin: 0;
        }
      }

      &-published {
        &--2 {
          font-weight: bold;
        }
      }

      h1, .h1 {
        font-size: 28px;
        line-height: 1.25;

        @media(min-width: 768px) {
          font-size: 2.25rem;
        }
      }

      @media (max-width: 767px) {
        h1, .h1 {
          margin-bottom: 5px;
        }
      }

      h1 a, .h1 a {
        color: inherit;
        border-bottom: 2px solid #ecc7ce;
      }

      h1 a:hover, .h1 a:hover, h1 a:focus, .h1 a:focus {
        text-decoration: none;
      }

      h2, .h2 {
        font-size: 1.875rem;
        line-height: 2.25rem;
        margin-top: 40px;
        margin-bottom: 25px;
      }

      @media (max-width: 767px) {
        h2, .h2 {
          font-size: 20px;
          line-height: 1.25;
          margin-top: 30px;
          margin-bottom: 15px;
        }
      }

      h2 a, .h2 a {
        color: inherit;
        border-bottom: 2px solid #ecc7ce;
      }

      h2 a:hover, .h2 a:hover, h2 a:focus, .h2 a:focus {
        text-decoration: none;
      }

      h3, .h3 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-top: 40px;
        margin-bottom: 25px;
      }

      @media (max-width: 767px) {
        h3, .h3 {
          font-size: 20px;
          line-height: 24px;
          margin-top: 30px;
          margin-bottom: 15px;
        }
      }

      h3 a, .h3 a {
        color: inherit;
        border-bottom: 2px solid #ecc7ce;
      }

      h3 a:hover, .h3 a:hover, h3 a:focus, .h3 a:focus {
        text-decoration: none;
      }

      &-overline {
        font-family: $font-family;
        font-size: 14px;

        @media(min-width: 768px) {
          font-size: 16px;
        }
      }

      &-header {
        margitop: 10px;
        margin-bottom: 20px;

        @media (min-width: 768px) {
          font-size: 1rem;
          margin-top: 20px;
          margin-bottom: 45px;
        }
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 25px;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-right: 10px;
        margin-bottom: 10px;

        a {
          font-size: 12px;
          font-family: $font-family;
          color: $white-color;
          background: $black-color;
          border-radius: 25px;
          padding: 5px 10px;
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, opacity 0.3s ease-in-out;

          &:hover {
            background-color: $orange-color;
          }
        }
      }
    }

    .category-teaser {
      &--lined {
        border: 1px solid $medium-gray-color;
        border-radius: $border-radius;
        padding: 15px;
      }

      &-snippet {
        .row {
          @media(min-width: 992px) {
            .category-teaser-media,
            .category-teaser-body {
              float: left;
              width: 50%;
            }
          }

          .category-teaser-media {
            img {
                width: 100%;
                height: auto;
            }
          }
        }

        @media(max-width: 767px) {
          margin: 45px 0;

          .category-teaser-description {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    .teaserslider-by-path {
      .pimcore_area_content {
        margin: 0;
      }

      .slider-dots {
        margin: 0 -5px;

        .category-teaser {
          margin-left: 5px !important;
          margin-right: 5px !important;
        }

        img {
          max-width: none;
          width: 100%;
        }
      }
    }
  }
}