.toc {
  margin-top: 35px;
  margin-bottom: 35px;
}

.toc .toc-handler {
  position: relative;
  padding: 17px 15px;
  cursor: pointer;
  background: $primary-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.toc .toc-handler > i {
  position: absolute;
  top: 20px;
  right: 15px;
  color: #fff;

  &:before {
    font-size: 20px;
  }
}

.toc .toc-title {
  font-family: $font-family;
  font-size: 16px;
  vertical-align: middle;
  color: #fff;
  font-weight: bold;
}

.toc .toc-title > i {
  font-size: 28px;
  margin-right: 15px;
  vertical-align: middle;
}

.toc > ul {
  @extend .list-links;
  border: 1px solid #f2f2f2;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  > li {
    padding: 18px 15px 15px;
    line-height: 1.1;

    > a {
      display: inline-block;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1.1;
      max-width: 100%;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover {
        color: $primary-color;
      }

      &:before {
        margin-top: -0.5px;
      }

      &::after {
        left: 15px;
        bottom: -1px;
        width: auto;
        right: 0;
      }
    }
  }
}

.toc-small-container {
  font-family: $font-family;
  font-size: 14px;
  line-height: 50px;
  position: fixed;
  z-index: 10;
  right: 15px;
  bottom: 15px;
  height: 50px;
  width: 100%;
  max-width: calc(100vw - 80px);
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  text-align: center;
  opacity: 0;
  border-radius: 25px;

  .list-disc {
    margin: 10px 15px;
  }
}

.toc-small-container.active {
  opacity: 1;
  background: $white-color;
}

.toc-small-container.toc-small-container--active {
  height: auto;
  text-align: left;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

  .chevron {
    &::before {
      display: block;
      position: relative;
      transform: rotate(180deg);
    }
  }
}

.toc-small-container.toc-small-container--active .show-message {
  display: none;
}

.toc-small-container.toc-small-container--active .close-message {
  display: inline;
}

.toc-small-container.toc-small-container--active .icon-icon-close {
  display: block;
}

.toc-small-container.toc-small-container--active > ul {
  display: block;
}

.js-toc-show-more {
  &-li {

    a {
      padding-left: 0 !important;
    }
  }
}

.toc-small-container > ul {
  position: relative;
  z-index: 11;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
}

.toc-small-container > ul > li {
  display: block;
  padding: 12px 15px;
  border-bottom: 1px solid #e3e4e4;
}

.toc-small-container > ul > li:last-child {
  border-bottom: 0;
}

.toc-small-container > ul > li > a {
  font-size: 14px;
  line-height: 16px;
  display: block;
  text-decoration: none;
  color: #000;

  &:hover {
    color: $primary-color;
  }

  &::after {
    left: 15px;
    bottom: -5px;
    width: auto;
    right: 0;
  }
}

.toc-small {
  text-align: left;
  position: relative;
  z-index: 11;
  display: block;
  width: 100%;
  height: 100%;
  padding-right: 30px;
  padding-left: 30px;
  vertical-align: middle;
  color: $white-color;
  background-color: $primary-color;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
}

.toc-small:hover, .toc-small:focus {
  text-decoration: none;
  color: #fff;
}

.toc-small > i {
  font-size: 28px;
  margin-right: 10px;
  vertical-align: middle;
}

.toc-small .close-message {
  display: none;
}

.toc-small .chevron {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

#toc-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: block;
  visibility: hidden;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #fff;
}

#toc-overlay.active {
  visibility: visible;
  opacity: 0.9;
}