
.reiseinspirationen {
  position: relative;
  margin: 25px 0 50px;

  @media(min-width: 768px) {
    margin: 50px 0 80px;
  }

  @media(min-width: 1200px) {
    margin: 75px 0 100px;
  }

  .pimcore_editable {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .pimcore_block_entry {
      width: 100%;
      max-width: 50%;
    }
  }

  .container {
    background: transparent !important;
  }

  .header {
    position: relative;
    font-family: $font-family;

    color: $dark-primary-color;

    @media(min-width: 768px) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .headline {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h2 {
        margin: 0;
        font-size: 28px;

        @media(min-width: 768px) {
          font-size: 36px;
        }
      }
    }

    [class^="fa-"] {
      margin-right: 15px;
      font-size: 44px;
    }

    .ad-tag {
      font-size: 12px;
      font-family: $font-family-body;
      color: #898B88;
      width: 100%;
      margin-top: 10px;

      @media(min-width: 768px) {
        position: absolute;
        bottom: 0;
        right: 0;
        width: auto;
        margin: 0;
      }
    }
  }

  .ad-container {
    position: relative;
    margin-top: 10px;
    padding: 1px;
    overflow: hidden;

    @media(min-width: 768px) {
      border: 1px solid $medium-gray-color;
      border-radius: 5px;
      padding: 15px;
    }

    //.inner:not(.slick-initialized),
    //.inner:not(.editmode)  {
    //  display: flex;
    //
    //  .item {
    //    &:nth-child(2),
    //    &:nth-child(3),
    //    &:nth-child(4),
    //    &:nth-child(5),
    //    &:nth-child(6),
    //    {
    //      display: none;
    //    }
    //  }
    //}

    @media(min-width: 768px) {
      margin: 20px 0;
      padding: 20px 30px;

      //.inner:not(.slick-initialized),
      //.inner:not(.editmode)  {
      //  display: flex;
      //
      //  .item {
      //    &:nth-child(2)
      //    {
      //      display: block;
      //    }
      //  }
      //}
    }

    @media(min-width: 1200px) {
      //.inner:not(.slick-initialized),
      //.inner:not(.editmode) {
      //  .item {
      //    &:nth-child(2),
      //    &:nth-child(3),
      //    &:nth-child(4),
      //    &:nth-child(5),
      //    &:nth-child(6) {
      //      display: block;
      //    }
      //  }
      //}
    }

    .inner {
      margin: 0 -10px;

      @media(min-width: 768px) {
        margin: 0 -20px;
      }
    }

    .slick-list {
      overflow: visible !important;
    }

    .slider-next,
    .slider-prev {
      background: $primary-color;
      color: $white-color;
      font-size: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 15px 8px;
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: $orange-color;
      }

      @media(min-width: 768px) {
        display: none;
      }
    }

    .slider-prev {
      left: 0;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      @media(min-width: 768px) {
        left: 20px;
      }
    }

    .slider-next {
      right: 0;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      @media(min-width: 768px) {
        right: 20px;
      }
    }

    .slick-dots {
      bottom: -50px;

      @media(min-width: 768px) {
        bottom: -75px;
      }

      li {
        width: 10px;
        margin: 0 5px;

        button {
          transform: scale(0.5);
          transform-origin: center;
          transition: transform 0.3s ease-in-out, color 0.3s ease-in-out, copacity 0.3s ease-in-out;

          &, &::before {
            width: 10px;
            height: 10px;
          }

          &::before {
            content: "";
            border-radius: 100%;
            background: $primary-color;
          }
        }

        &.slick-active {
          button {
            transform: scale(1);
          }
        }
      }
    }

    .item {
      color: $black-color;
      margin: 0 10px;
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
      width: 100%;
      aspect-ratio: 530 / 226;
      position: relative;

      a {
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        outline: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.desktop-nav {
      @media(min-width: 768px) {
        .slider-prev,
        .slider-next {
          display: block;
        }
      }
    }
  }
}

.snippet {
  .container {
    padding: 0;
    width: 100%;
  }

  .reiseinspirationen {
    display: none;

    @media(min-width: 768px) {
        display: block;
    }

    .header {
      display: block;

      [class^="fa-"] {
        color: $primary-color;
      }

      h2 {
        margin: 0 !important;
        font-size: 34px;
      }

      .ad-tag {
        margin-top: 15px;
        position: relative;
        left: 0;
        right: auto;
      }
    }

    .ad-container {
      border: none;
      padding: 5px 0 50px;
      margin: 0;

      .slick-dots {
        bottom: -35px;
      }

      .inner {
        margin: 0;
      }

      .item {
        margin: 0;
      }

      .slider-prev {
        left: 0;
      }

      .slider-next {
        right: 0;
      }
    }
  }
}