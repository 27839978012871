.gallery-pagination {
  font-family: $font-family-secondary;
  color: $primary-color;
  font-style: normal;

  @media(min-width: 768px) {
    font-size: 60px;
  }
}

.article-gallery {
  .slick-list {
    border-radius: $border-radius;
  }
}