.find-my-plant-link {
  position: fixed;
  right: 25px;
  bottom: 100px;
  display: none;
  z-index: 999999;

  @media (min-width: 768px) {
    display: block;
  }

  .text {
    font-family: $font-family;
    background: $black-color;
    color: $white-color;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 10px;
    position: absolute;
    top: 50%;
    right: 55px;
    transform: translate(-10px, -50%);
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  .icon {
    display: block;
    width: 48px;
    height: 48px;
    background-color: $white-color;
    border-radius: 100%;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 20px #00000033;

    img {
      width: 26px;
      height: 26px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      transition: all 0.3s ease-in-out;

      &.hover {
        opacity: 0;
      }
    }
  }

  a:hover {
    .text {
      opacity: 1;
      transform: translate(0, -50%);
    }

    .icon {
      background-color: $primary-color;
    }

    img {
      opacity: 0;
    }

    .hover {
      opacity: 1;
    }
  }
}

#page {
  &.find-my-plant {
    overflow-x: hidden;

    .wrap {
      position: relative;
    }

    .loader {
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      pointer-events: none;
    }

    .results {
      opacity: 1;
    }

    .loader,
    .results {
      transition: all 0.3s ease-in-out;
    }

    .is-loading {
      .loader {
        opacity: 1;
        pointer-events: all;
      }

      .results {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .breadcrumb {
      padding-top: 65px;
    }

    .active-filters {
      display: block;
      margin-top: 35px;
    }

    .results {
      padding-bottom: 60px;
    }

    .search-form {
      margin: 0 -15px;
      position: relative;
      padding: 15px;
      background: transparent linear-gradient(107deg, #678E60 0%, #678E60 29%, #84B37B 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 10px 30px #0000001A;

      @media(min-width: 768px) {
        padding: 35px;
        border-radius: $border-radius;
      }

      .header {
        @media(min-width: 768px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .wrap {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          padding-left: 65px;
        }

        .icon {
          display: block;
          width: 55px;
          height: 55px;
          background-color: $white-color;
          border-radius: 100%;
          transition: all 0.3s ease-in-out;
          position: absolute;
          left: 0;
          transform: translateY(5px);

          img {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .search {
          margin-top: 25px;
          width: 100%;
          position: relative;

          @media(min-width: 768px) {
            margin-top: 0;
            width: 50%;
          }

          .btn-search {
            border: none;
            outline: none;
            background: transparent;
            color: $primary-color;
            font-size: 24px;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: $white-color;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.3s ease-in-out;
            z-index: 9;
          }
        }
      }

      input.form-control {
        background: $white-color;
        border-radius: 50px;
        padding: 25px 15px;

        @media(min-width: 768px) {
          padding: 30px 20px;
        }
      }

      .reset {
        display: none;

        @media(min-width: 768px) {
          display: block;
        }
      }
    }

    .form-check {
      label {
        position: relative;
        padding-left: 35px;
        display: block;
        height: 25px;
        vertical-align: middle;
        line-height: 25px !important;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 5px 0;
        font-family: $font-family;

        @media(min-width: 768px) {
          padding-left: 40px;
        }

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          border: 1px solid $white-color;
          background-color: $white-color;
          width: 25px;
          height: 25px;
          border-radius: 3px;
          content: "";
          display: inline-block;
          margin: 0 10px 0 0;
          flex-shrink: 0;
          pointer-events: none;
          transform: translateY(-50%);
        }

        &::after {
          font-family: "Font Awesome 6 Pro";
          position: absolute;
          left: 0;
          top: 50%;
          width: 25px;
          height: 25px;
          border-radius: 3px;
          content: "\f00c";
          vertical-align: middle;
          text-align: center;
          display: inline-block;
          flex-shrink: 0;
          color: $primary-color;
          opacity: 0;
          transition: all 0.3s ease-in-out;
          transform: translateY(-50%);
        }
      }

      input[type="checkbox"]:checked {
        & + label {
          &::after {
            opacity: 1;
          }
        }
      }

      input {
        display: none !important;
      }
    }

    .filters-reset {
      position: relative;
      box-shadow: 0px 10px 30px #0000001A;

      @media(max-width: 767px) {
        margin: 0 -15px;
        padding: 0 15px;
        display: none;

        &.has-tags {
          display: block;
        }
      }

      @media(min-width: 768px) {
        box-shadow: none;
      }

      .reset {
        @media(min-width: 768px) {
          display: none;
        }
      }

      &.has-tags {
        .filter-tags {
          margin: 0;
          padding: 15px 0 10px;

          @media(min-width: 768px) {
            margin-top: 35px;
            padding: 0;
          }
        }
      }
    }

    .filters {
      padding-top: 15px;
      border-top: 1px solid rgba(255, 255, 255, 0.10);
      margin: 15px -8px 0;

      @media(min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 35px;
        margin-top: 35px;
      }

      .dropdown-categories,
      .dropdown-multichoice {
        padding: 0 8px;
        margin-bottom: 8px;

        @media(min-width: 1024px) {
          margin-bottom: 0;
        }
      }

      .dropdown-categories {
        width: 100%;

        @media(min-width: 768px) {
          width: 100%;
        }

        @media(min-width: 1024px) {
          width: 30%;
        }
      }

      .dropdown-multichoice {
        width: 100%;

        @media(min-width: 768px) {
          width: 25%;
        }

        @media(min-width: 1024px) {
          width: 17.5%;
        }
      }

      .filter-label {
        display: block;
        background: $white-color;
        border-radius: 4px;
        padding: 10px;
        position: relative;
        cursor: pointer;

        i {
          font-size: 20px;
          color: $primary-color;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.3s ease-in-out;
        }
      }

      .dropdown {
        > .form-control {
          position: absolute;
          background: $white-color;
          border-radius: 4px;
          height: auto;
          max-height: 300px;
          max-width: calc(100vw - 30px);
          overflow-y: auto;
          scroll-behavior: smooth;
          z-index: 999;
          padding: 5px 0;
          opacity: 0;
          transform: translateY(10px);
          transition: all 0.3s ease-in-out;
          pointer-events: none;

          > ul {
            > li {
              > .form-check {
                color: $black-color;
              }
            }
          }

          > .form-check {
            color: $black-color;
          }
        }

        &.is-open {
          > .form-control {
            pointer-events: all;
            opacity: 1;
            transform: translateY(0);

            .form-check {
              pointer-events: all;
            }
          }

          .filter-label i {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .form-check {
          padding: 4px 0;
          position: relative;
          border-bottom: 1px solid $light-gray-color;

          .form-check-label {
            &::before {
              border: 1px solid #BFC0BFFF;
              left: 10px;
            }

            &::after {
              left: 10px;
            }

            padding-left: 45px;
          }

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            background: $light-gray-color;
            transition: all 0.3s ease-in-out;
            opacity: 0;
          }

          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }

        ul {
          li {
            ul {
              > li {
                > .form-check {
                  padding-left: 15px;
                }
              }
            }

            &.inherit {
              ul {
                label {
                  &::after {
                    opacity: 1;
                    color: $gray-color;
                  }
                }
              }
            }
          }
        }

        &-counter {
          font-size: 12px;
          font-family: $font-family;
          color: $white-color;
          background: $dark-primary-color;
          border-radius: 25px;
          width: 20px;
          height: 20px;
          position: absolute;
          text-align: center;
          vertical-align: middle;
          line-height: 20px;
          top: 50%;
          right: 35px;
          transform: translateY(-50%);
        }

        .js-collapse {
          > .form-check {
            position: relative;

            .js-collapse-toggle {
              position: absolute;
              top: 50%;
              right: 5px;
              width: 40px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              vertical-align: middle;
              transform: translateY(-50%);
              z-index: 3;
              cursor: pointer;
            }
          }

          > ul {
            display: none;
          }

          &.is-open {
            > ul {
              display: block;
            }

            .js-collapse-toggle {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }
    }

    .checkboxes {
      margin: 25px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media(min-width: 768px) {
        margin: 35px 0;
      }

      .form-check {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $white-color;
        font-family: $font-family;
        margin-right: 20px;

        @media(min-width: 768px) {
          margin-right: 30px;
        }

        .form-check-input {
          width: 25px;
          height: 25px;
          border-radius: 3px;
          border: none;
          display: inline-block;
          margin: 0 10px 0 0;

          @media(min-width: 768px) {
            width: 40px;
            height: 40px;
          }
        }

        .form-check-label {
          line-height: 1;
          margin: 0 !important;

          @media(max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }

    .reset {
      @media(max-width: 767px) {
        position: relative;
        background: $white-color;
        margin: 0 -15px -15px;
        padding: 15px;

        &, a {
          display: block;
          color: $black-color !important;
          font-weight: 600;
          font-size: 12px;

          i {
            font-size: 16px;
          }
        }

        a {
          &:after {
            content: none;
          }
        }

        // add chevorn up
        &::before {
          font-family: "Font Awesome 6 Pro";
          content: "\f077";
          position: absolute;
          top: 50%;
          right: 20px;
          transition: all 0.3s ease-in-out;
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &.active {
        &::before {
          transform: translateY(-50%);
        }
      }

      @media(min-width: 768px) {
        display: block;
        position: absolute;
        right: 35px;
        bottom: 35px;
      }

      &, a {
        color: $white-color
      }
    }

    .filter-tags {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      @media(min-width: 768px) {
        &-collapse {
          &.hide {
            display: block !important;
          }
        }
      }

      .filter-tag {
        background: $dark-primary-color;
        color: $white-color;
        font-family: $font-family;
        font-size: 10px;
        padding: 5px 8px;
        border-radius: 25px;
        margin: 0 5px 5px 0;
        cursor: pointer;
        white-space: nowrap;

        @media(min-width: 768px) {
          padding: 5px 10px;
          font-size: 12px;
          margin: 0 10px 10px 0;
          font-weight: 700;
        }

        i {
          margin-right: 7px;
        }

        &:hover {
          background: $orange-color;
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 65px;
      list-style: none;

      .page-item {
        border-radius: 2px;
        border: 1px solid $primary-color;
        padding: 10px;
        margin: 0 5px;

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }

        &.active {
          background: $primary-color;

          &, a {
            color: $white-color;
          }
        }
      }
    }

    article {
      position: relative;
      border-top: 1px solid $medium-gray-color;
      margin-top: 15px;
      padding-top: 15px;

      @media(min-width: 768px) {
        margin-top: 35px;
        padding-top: 35px;
        display: flex;
      }

      .is-headline {
        @media(max-width: 767px) {
          margin-top: 15px;
        }
      }

      .overlay-effect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .media {
        border-radius: $border-radius;
        flex-shrink: 0;
        aspect-ratio: 4 / 3;
        overflow: hidden;
        position: relative;

        @media(min-width: 768px) {
          width: 30%;
          margin-right: 30px;
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }

      .content {
        flex: 1;

        .plant-char {
          margin: 25px 0 0 0;

          &-body {
            border: none;
            padding: 0;
          }

          &-table {
            padding: 0;
            border: none;
          }

          &-checkboxes {
            padding-left: 0;
          }
        }
      }

      .tag {
        position: absolute;
        top: 15px;
        right: 15px;
        background: $white-color;
        border-radius: 25px;
        padding: 5px 10px;
        color: $primary-color;
        pointer-events: none;
        font-family: $font-family;
        font-weight: 700;
        font-size: 11px;

        @media(min-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .autosuggest {
      &-results {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: $white-color;
        border-radius: $border-radius;
        z-index: 999;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        pointer-events: none;

        &.active {
          opacity: 1;
          transform: translateY(10px);
          box-shadow: 0px 0px 20px #00000033;
          pointer-events: all;
        }

        //@media(max-width: 767px) {
        //  max-height: 70svh;
        //  overflow-y: auto;
        //  scroll-behavior: smooth;
        //}

        .no-results {
          padding: 10px !important;
          margin: 0 !important;
          font-size: 16px;
          font-weight: 500;
        }
      }

      &-item {
        margin: 0;
        padding: 15px 15px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        align-items: center;
        justify-content: flex-start;
        display: flex;

        .media {
          aspect-ratio: 1 / 1;
          width: 80px !important;
          margin-right: 15px;
          flex-shrink: 0;
        }

        .is-headline {
          &.small {
            margin: 0;
            font-size: 16px;

            @media(min-width: 768px) {
              font-size: 24px;
            }
          }
        }

        .article-overline {
          margin: 0;
        }

        &:hover {
          background: $light-gray-color;
        }

        mark {
          padding: 0;
          background: none;
          font-weight: 900;
        }
      }
    }
  }
}

.find-my-plant-overlay {
  position: fixed;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;

  .wrap {
    transform: translateY(25%);
    width: 90vw;
    max-width: 480px;
    background: $white-color;
    box-shadow: 0px 5px 20px #0000000D;
    border-radius: $border-radius;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    @media(min-width: 768px) {
      display: flex;
      max-width: 970px;
    }

    .media {
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      aspect-ratio: 16 / 9;

      @media(min-width: 768px) {
        padding: 50px;
        width: 40%;
        min-width: 320px;
        aspect-ratio: auto;
        justify-content: flex-start;
        align-items: flex-end;
      }

      > picture > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }

      .fmp-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 40px;
        color: $white-color;
        z-index: 2;

        @media(min-width: 768px) {
          padding-left: 65px;
          padding-top: 0;
        }

        .icon {
          display: block;
          width: 55px;
          height: 55px;
          background-color: $white-color;
          border-radius: 100%;
          transition: all 0.3s ease-in-out;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: 0px 0px 20px #00000033;

          @media(min-width: 768px) {
            top: auto;
            left: 0;
            transform: translateY(5px);
          }

          img {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .headline {
          line-height: 1;
          font-size: 3.75rem;
          font-family: $font-family-tertiary;
          color: $white-color;
          text-shadow: 0 5px 10px rgba(0, 0, 0, 0.45);
          margin: 0 !important;
          padding-top: 15px;

          @media(max-width: 767px) {
            font-size: 54px;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }
    }

    .content {
      padding: 25px 15px;
      text-align: center;

      @media(min-width: 768px) {
        padding: 50px;
        width: 60%;
        max-width: 450px;
        text-align: left;
      }

      .subline {
        line-height: 1.1;

        @media(max-width: 767px) {
          font-size: 28px;
        }
      }

      .teaser {
        margin: 20px 0 30px;
      }
    }
  }

  .close-overlay {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    font-size: 24px;
    color: $white-color;
    background: none;
    border: none;
    appearance: none;
    -webkit-appearance: none;
    z-index: 4;

    @media(min-width: 768px) {
      color: $dark-primary-color;

    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;

    .wrap {
      opacity: 1;
      transform: translateY(0);
    }
  }
}