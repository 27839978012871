.landschaufenster {
  padding: 30px 0 50px;
  margin: 15px 0;
  color: $white-color;
  background-color: $dark-primary-color;
  background-image: url(../images/pattern.png);
  background-repeat: repeat;
  background-position: top left;
  overflow: hidden;

  @media(min-width: 768px) {
    padding: 50px 0;
    margin: 50px 0;
  }

  @media(min-width: 1200px) {
    padding: 60px 0;
    margin: 60px 0;
  }

  .pimcore_editable {
    display: flex;
    width: 100%;

    .pimcore_block_entry {
      width: 100%;
      max-width: 25%;
    }
  }

  .container {
    background: transparent !important;
  }

  .ad-tag {
    font-size: 12px;
    margin: 0;
  }

  .headline {
    font-family: $font-family-secondary;
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 10px;
    font-size: 20px;

    @media(min-width: 768px) {
      font-size: 24px;
    }

    span {
      display: block;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
      margin-left: -16px;
      margin-bottom: 0px;
    }
  }

  .ad-container {
    margin: 15px -10px;

    //&:not(.slick-initialized),
    //&:not(.editmode)  {
    //  display: flex;
    //
    //  .item {
    //    &:nth-child(3),
    //    &:nth-child(4) {
    //      display: none;
    //    }
    //  }
    //}

    @media(min-width: 768px) {
      margin: 50px -10px;

      //&:not(.slick-initialized),
      //&:not(.editmode)  {
      //  .item {
      //    &:nth-child(3) {
      //      display: block;
      //    }
      //  }
      //}
    }

    @media(min-width: 1200px) {
      //&:not(.slick-initialized),
      //&:not(.editmode)  {
      //  .item {
      //    &:nth-child(4) {
      //      display: block;
      //    }
      //  }
      //}
    }

    .slick-list {
      overflow: visible !important;

      @media(max-width: 767px) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    .slick-dots {
      bottom: -50px;

      @media(min-width: 1200px) {
        display: none;
      }

      li {
        margin: 0 5px;
        width: 10px;

        button {
          transform: scale(0.5);
          transform-origin: center;
          transition: transform 0.3s ease-in-out, color 0.3s ease-in-out, copacity 0.3s ease-in-out;

          &, &::before {
            width: 10px;
            height: 10px;
          }

          &::before {
            content: "";
            border-radius: 100%;
            background: $white-color;
          }
        }

        &.slick-active {
          button {
            transform: scale(1);
          }
        }
      }
    }

    .item {
      color: $black-color;
      background: $white-color;
      padding: 8px;
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.35);
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
      width: 100%;

      &:hover {
        box-shadow: 0px 6px 11px 2px rgba(0, 0, 0, 0.35);
        transform: translateY(-3px);
      }

      a {
        display: block;
        aspect-ratio: 260 / 350;
        overflow: hidden;
        outline: none;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}