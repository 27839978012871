.bg {
  &-white {
    background-color: $white-color;
  }

  &-primary {
    background-color: $primary-color;
  }

  &-transparent {
    background-color: transparent;
  }
}

.text {
  &-white {
    color: $white-color;
  }

  &-primary,
  &-green {
    color: $primary-color;
  }

  &-dark-primary {
    color: $dark-primary-color;
  }

  &-red {
    color: $red-color;
  }

  &-orange {
    color: $orange-color;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-center {
    text-align: center;
  }

  &-current {
    color: currentColor;
  }

  &-xs {
    font-size: 12px;
  }
}

.font {
  &-bold {
    font-weight: bold;
  }

  &-semibold {
    font-weight: 600;
  }

  &-default,
  &-primary {
    font-family: $font-family;
  }

  &-secondary {
    font-family: $font-family-secondary;
  }

  &-tertiary {
    font-family: $font-family-tertiary;
  }

  &-body {
    font-family: $font-family-body;
  }

  &-14 {
    font-size: 1.375rem;

    @media (min-width: 768px) {
      font-size: 0.875rem;
    }
  }
}

.is-roofline {
  font-size: 1.375rem;
  color: $gray-color;
  font-family: $font-family;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
}

.is-teaser {
  font-size: 1.825rem;
  font-family: $font-family-body;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.is-headline {
  font-size: 3rem;
  font-family: $font-family-secondary;

  @media (min-width: 768px) {
    font-size: 2.25rem;
  }

  &.small {
    font-size: 2.25rem;

    @media (min-width: 768px) {
      font-size: 1.75rem;
    }
  }

  &.big {
    font-size: 6.75rem;
    font-family: $font-family-tertiary;
    color: $primary-color;
    line-height: 1;
    letter-spacing: normal;

    @media (min-width: 768px) {
      font-size: 5.25rem;
    }

    @media (min-width: 1200px) {
      font-size: 6.25rem;
    }
  }
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-5 {
  margin-right: 5px;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-100 {
  z-index: 100;
}

.w-full {
  width: 100%;
}

.opacity-0 {
  opacity: 0;
}

.list-links {
  margin: -5px 0;
  padding: 0;
  list-style: none;

  > li {
    padding: 15px 0;
    border-bottom: 1px solid #efefef;

    > a {
      font-family: $font-family;
      font-size: 0.875rem;
      font-weight: 900;
      line-height: 0.08928571rem;
      clear: both;
      padding: 5px 0 5px 15px;
      white-space: nowrap;
      letter-spacing: 0.05em;
      color: #000;
      position: relative;

      &:before {
        border: 1px solid currentColor;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.background-color {
  background-color: $primary-color;
}

.list-disc {
  margin: -5px 0;
  padding: 0;
  list-style: none;

  > li {
    border-bottom: 1px solid #efefef;
    position: relative;
    padding: 10px 0 10px 15px;

    &:before {
      border: 1px solid currentColor;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      content: "";
      position: absolute;
      top: 1em;
      left: 0;
      transform: translateY(25%);
    }
  }
}

.list-none {
  margin: -5px 0;
  padding: 0;
  list-style: none;
}

.adminbox {
  padding: 15px;
  background: $light-gray-color;
  border-radius: $border-radius;
  margin: 15px 0;
}

.border {
  &-radius {
    border-radius: $border-radius;
    perspective: 0;
  }
}