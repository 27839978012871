$primary-color: var(--primary-color);
$light-primary-color: #f2f7f2;
$medium-primary-color: #bbd5b7;
$dark-primary-color: #678E60;
$black-color: #000;
$white-color: #fff;
$gray-color: #bfc0bf;
$medium-gray-color: #F2F2F2;
$light-gray-color: #F8F8F8;
$orange-color: #C47A50;
$red-color: #e03f37;
$font-family: "Merriweather", sans-serif;
$font-family-secondary: "Times Sans Serif", sans-serif;
$font-family-tertiary: "Italianno", sans-serif;
$font-family-body: "Lato", sans-serif;
$border-radius: 10px;