.plant-char {
  margin-top: 35px;
  margin-bottom: 35px;

  &-handler {
    position: relative;
    padding: 17px 15px;
    cursor: pointer;
    background: $dark-primary-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    > i {
      position: absolute !important;
      top: 20px !important;
      right: 15px !important;
      color: #fff;

      &:before {
        font-size: 20px;
      }
    }
  }

  &-title {
    font-family: $font-family;
    font-size: 16px;
    vertical-align: middle;
    color: #fff;
    font-weight: bold;

    > i {
      font-size: 28px;
      margin-right: 15px;
      vertical-align: middle;
    }
  }

  &-body {
    border: 1px solid #f2f2f2;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 15px 0;
  }

  &-table {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 10px;
    padding: 0 15px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $light-gray-color;

    @media(min-width: 768px) {
      grid-gap: 15px;
      padding: 0 15px 30px;
      margin-bottom: 30px;
    }

    .label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      font-family: $font-family;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      grid-column: span 4 / span 4;

      @media(min-width: 768px) {
        font-size: 0.875rem;

        grid-column: span 1 / span 1;
      }
    }

    .value {
      font-family: $font-family;
      grid-column: span 4 / span 4;
      font-size: 10px;
      margin-bottom: 5px;

      @media(min-width: 768px) {
        font-size: 0.875rem;
        grid-column: span 3 / span 3;
        margin-bottom: 0;
      }
    }

    .months {
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      grid-gap: 0px;
      border-radius: 15px;
      background: $light-primary-color;
      text-align: center;
      font-size: 10px;
      font-family: $font-family;
      font-weight: bold;
      overflow: hidden;

      @media(min-width: 440px) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
      }

      @media(min-width: 768px) {
        border-radius: 25px;
        font-size: 0.625rem;
      }

      > * {
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        height: 100%;
        padding: 3px 2px;

        &.active {
          background: $medium-primary-color;
        }

        &::after {
          // dotted line
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 1px;
          border-right: 1px dashed $white-color;
          pointer-events: none;
        }

        @media(max-width: 767px) {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            &::after {
              top: auto;
              left: 0;
              right: 0;
              bottom: 0;
              height: 1px;
              width: auto;
              border-bottom: 1px dashed $white-color;
            }
          }
        }
      }
    }
  }

  &-simple {
    @media(max-width: 767px) {
      grid-gap: 5px;

      .value, .label {
        font-size: 12px;
      }
    }
  }

  &-checkboxes {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 15px;
    padding: 0 15px 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $light-gray-color;
    font-weight: bold;
    font-family: $font-family;
    font-size: 12px;

    @media(min-width: 440px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media(min-width: 768px) {
      font-size: 0.875rem;
    }

    .label {
      position: relative;
      padding-left: 30px;

      &.with-icon {
        i {
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          margin-right: 5px;
          font-size: 20px;
        }
      }
    }
  }

  &-table,
  &-checkboxes {
    &:last-child {
      border-bottom: none;
      margin: 0;
    }
  }
}