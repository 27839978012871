.link-hover-animation {
  display: inline-block;
  position: relative;
  color: currentColor;
  text-decoration: none !important;
  transition: color 0.25s ease-out;

  &::after {
    position: absolute;
    display: block;
    width: 100%;
    background-color: currentColor;
    left: 0;
    content: '';
    height: 1px;
    bottom: 1px;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &.btn {
    &::after {
      width: auto;
      left: 25px;
      right: 25px;
      bottom: 15px;

      @media(min-width: 768px) {
        bottom: 20px;
        left: 35px;
        right: 35px;
      }
    }
  }
}

.overlay-effect {
  position: relative;
  outline: none !important;
  -webkit-perspective: 0;
  perspective: 0;

  &:before,
  &:after {
    visibility: hidden;
    opacity: 0;
    backdrop-filter: blur(2px);
    pointer-events: none;
  }

  &::before {
      font-family: $font-family;
      font-size: 0.875rem;
      font-weight: bold;
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      display: block;
      width: auto;
      margin: 0 auto;
      content: attr(data-title);
      transform: translate(-50%, -50%);
      text-align: center;
      letter-spacing: 0;
      color: #fff;
      transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.5);
    transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  &:hover {
    &::before,
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }
}